import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo2.png';
import profileUser from '../assets/profile-user.png';
import { useAuth } from '../hooks/useAuth';
import '../css/BulkChatStyle.css';
import {db} from "../firebase";
import {doc, deleteDoc,setDoc, getDoc} from "firebase/firestore";

const BulkChat = () => {
    const { currentUser } = useAuth();
    const user_id = currentUser.uid;
    const chatEndRef = useRef(null);
    const [message, setMessage] = useState('');
    const [maxLength, setMaxLength] = useState(150);
    const [maxK, setMaxK] = useState(2);
    const [dayNumber, setDayNumber] = useState('');
    const [personaNumber, setPersonaNumber] = useState('');
    const [userHistory, setUserHistory] = useState('');
    const [personaText, setPersonaText] = useState('');
    const [error, setError] = useState(null);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [historyGenerated, setHistoryGenerated] = useState(false);
    const [assistantStep, setAssistantStep] = useState('');
    const [historyVerified, setHistoryVerified] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [assistantLoading, setAssistantLoading] = useState(false);
    const [closingDay, setClosingDay] = useState(false);
    const [savingDay, setSavingDay] = useState(false);
    const [showUserWarning, setShowUserWarning] = useState(false);
    const [showAssistantWarning, setShowAssistantWarning] = useState(false);

    const [conversation, setConversation] = useState([]);
    const textareaRef = useRef(null);
    const editTextRef = useRef(null);
    const [editText, setEditText] = useState('');
    const [editingMessageId, setEditingMessageId] = useState(null);

     const baseUrl="https://app.metamindful.ai:4000";
//    const baseUrl="";

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
        }
    }, [personaText,assistantStep]); // Runs whenever personaText updates
    useEffect(() => {
        if (editTextRef.current) {
            editTextRef.current.style.height = "auto"; // Reset height
            editTextRef.current.style.height = `${editTextRef.current.scrollHeight}px`; // Set new height
        }
    }, [editText]);

    // Handle double-click to start editing
    const handleEdit = (id, content) => {
        setEditingMessageId(id);
        setEditText(content);
    };

    // Save edited message
    const handleSaveEdit = () => {
        setConversation(prev =>
            prev.map(msg =>
                msg.id === editingMessageId ? { ...msg, content: editText } : msg
            )
        );
        setEditingMessageId(null);
        setEditText('');
    };

    // Cancel editing
    const handleCancelEdit = () => {
        setEditingMessageId(null);
        setEditText('');
    };

    const closeDay = async (e) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
        setClosingDay(true);

        try {
            const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

            const url=`${baseUrl}/save?day_number=${dayNumber}&persona_number=${personaNumber}`;
            const response = await axios.post(url, conversationFilter, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            const { status } = response.data;

            // Handle success
            if (status === "success") {
                const docId = `${dayNumber}_${personaNumber}`;
                const docRef = doc(db, "saved_chats", docId);

                await deleteDoc(docRef);
                setMessage("Day Closed");
            } else {
                setError("Failed to save conversation history. Please try again.");

            }

            setClosingDay(false);

        } catch (error) {
            setClosingDay(false);

            setError("Failed to save conversation history. Please try again.");
        }
    };
    const saveDay = async (e) => {
        e.preventDefault();
        setMessage(null);
        setSavingDay(true);

        try {
            const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

            const dataToSend = {
                "day_number": dayNumber,
                "persona_number": personaNumber,
                "persona": personaText,
                "user_history": userHistory,
                "conversation": conversationFilter,
                "user_id": user_id,
                "timestamp": new Date().toISOString()
            };

            const docId = `${dayNumber}_${personaNumber}`;

            // Reference to Firestore document inside "saved_chats"
            const docRef = doc(db, "saved_chats", docId);

            // Update existing document or insert if it doesn’t exist
            await setDoc(docRef, dataToSend, { merge: true });

            setMessage("✅ Day Saved Successfully");
            setSavingDay(false);

        } catch (error) {
            setError("❌ Failed to save conversation history. Please try again.");
            setSavingDay(false);
        }
    };

    const handleGenerateHistoryOld = async (e) => {
        e.preventDefault();
        setError(null);
        setHistoryLoading(true);

        if (!dayNumber || !personaNumber) {
            setError("Day Number and Persona Number are required.");
            setHistoryLoading(false);
            return;
        }

        try {
            const url = `${baseUrl}/generate_history?day_number=${dayNumber}&persona_number=${personaNumber}`;
            const response = await axios.post(url);
            const responseData = response.data;

                setPersonaText(responseData.current_day_history_with_user);
                setUserHistory(responseData.current_day_history_with_metamindful);
                const chatData = responseData.conversation.map((msg, index) => ({
                    id: index + 100,
                    role: msg.role,
                    content: msg.content,
                }));
                setConversation(chatData);
                setHistoryGenerated(true);
                setHistoryVerified(false); // Reset verification when regenerating

        } catch (error) {
            setError("Failed to fetch conversation history. Please try again.");
        }
        setHistoryLoading(false);
    };

    const handleGenerateHistory = async (e) => {
        e.preventDefault();
        setError(null);
        setHistoryLoading(true);

        if (!dayNumber || !personaNumber) {
            setError("Day Number and Persona Number are required.");
            setHistoryLoading(false);
            return;
        }

        try {
            const docId = `${dayNumber}_${personaNumber}`;
            const docRef = doc(db, "saved_chats", docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists() && !historyGenerated) {
                // Load saved history from Firestore
                const savedData = docSnap.data();

                setPersonaText(savedData.persona || "");
                setUserHistory(savedData.user_history || "");
                setConversation(savedData.conversation || []);
                setHistoryGenerated(true);
                setHistoryVerified(false); // Mark as verified since it's saved

                setMessage("✅ Loaded Saved History");

            } else {
                // No saved history found, call API to generate new history
                const url = `${baseUrl}/generate_history?day_number=${dayNumber}&persona_number=${personaNumber}`;
                const response = await axios.post(url);
                const responseData = response.data;

                setPersonaText(responseData.current_day_history_with_user);
                setUserHistory(responseData.current_day_history_with_metamindful);

                const chatData = responseData.conversation.map((msg, index) => ({
                    id: index + 100,
                    role: msg.role,
                    content: msg.content,
                }));

                setConversation(chatData);
                setHistoryGenerated(true);
                setHistoryVerified(false); // Reset verification when regenerating
            }

        } catch (error) {
            setError("❌ Failed to fetch conversation history. Please try again.");
        }

        setHistoryLoading(false);
    };
    const handleUserResponse = async (e) => {
        e.preventDefault();

        // Validate inputs
        const dayNum = parseInt(dayNumber, 10);
        const personaNum = parseInt(personaNumber, 10);

        if (isNaN(dayNum) || dayNum < 1 || dayNum > 100) {
            setError("Day number must be between 1 and 100.");
            return;
        }
        if (isNaN(personaNum) || personaNum < 1) {
            setError("Persona number is required and must be at least 1.");
            return;
        }

        setError(null); // Clear any existing errors
        setUserLoading(true);

        try {
            //    const url='https://app.metamindful.ai:4000/generate_history?day_number=${dayNum}&persona_number=${personaNum}';
            const url=`${baseUrl}/gen_user?day_number=${dayNum}&persona_number=${personaNum}&user_history=${userHistory}`;
     //       const conversationPayload = conversation.map(({ role, content }) => ({ role, content }));


            const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

            let lastUserIndex = conversation.length - 1;
            while (lastUserIndex >= 0 && conversation[lastUserIndex].role === 'user') {
                lastUserIndex--;
            }
            const newConversation = conversationFilter.slice(0, lastUserIndex + 1);



            const response = await axios.post(url,newConversation);

            const responseData = response.data;

            if (Array.isArray(responseData) ) {
                const multiple = responseData.length > 1?true:false;
                // remove BLUEPRINT_STEP_2 User: from messges
                const chatData = responseData.map((msg, index) => ({
                    id: index+1000,
                    role: 'user',
                    content: msg.replace(/BLUEPRINT_STEP_\d+ User:\s*/g, ''),
                    multiple: multiple,

                }));
                setConversation([...newConversation, ...chatData]);

            } else {
                setError("Invalid response from the server.");
            }
        } catch (error) {
            setError("Failed to fetch conversation history. Please try again.");
        }
        setUserLoading(false);

    };
    const handleVerifyHistory = () => {
        setHistoryVerified(true);
        setMessage("✅ History Verified Successfully!");
    };
    const handleAssistantResponse = async (e) => {
        e.preventDefault();

        // Validate inputs
        const dayNum = parseInt(dayNumber, 10);
        const personaNum = parseInt(personaNumber, 10);

        if (isNaN(dayNum) || dayNum < 1 || dayNum > 100) {
            setError("Day number must be between 1 and 100.");
            return;
        }
        if (isNaN(personaNum) || personaNum < 1) {
            setError("Persona number is required and must be at least 1.");
            return;
        }

        setError(null); // Clear any existing errors
        setAssistantLoading(true);
          const conversationFilter = conversation.map(({ role, content }) => ({ role, content }));

        // Find the index of the last assistant message
        let lastAssistantIndex = conversation.length - 1;
        while (lastAssistantIndex >= 0 && conversation[lastAssistantIndex].role === 'assistant') {
            lastAssistantIndex--;
            console.log(lastAssistantIndex);
        }

        // Remove all consecutive last assistant messages
        const newConversation = conversationFilter.slice(0, lastAssistantIndex + 1);

        const dataToSend = {
            "messageTime": new Date().toISOString(),
            "ID": user_id,
            "day_number": dayNumber,
            "conversation": newConversation,
            "max_token": maxLength,
            "k": maxK,
        };
        try {
            //    const url='https://app.metamindful.ai:4000/generate_history?day_number=${dayNum}&persona_number=${personaNum}';
            const url=`${baseUrl}/generate?day_number=${dayNum}`;
            const response = await axios.post(url,dataToSend);

            const responseData = response.data.response;
            setAssistantStep(response.data.current_step);

            if (response.data && Array.isArray(response.data.response)) {
                const multiple = responseData.length > 1?true:false;

                const chatData = responseData.map((msg, index) => ({
                    id: index+10000,
                    role: 'assistant',
                    content: msg,
                    multiple: multiple,
                }));
                setConversation([...newConversation, ...chatData]);

                // setConversation(prev => [
                //     ...prev, // Keep existing messages
                //     ...chatData // Append new messages
                // ]);

            } else {
                setError("Invalid response from the server.");
            }
        } catch (error) {
            setError("Failed to fetch conversation history. Please try again.");
        }
        setAssistantLoading(false);

    };

    // const handleDeleteResponse = (id) => {
    //     setConversation(prev => prev.filter(msg => msg.id !== id));
    // };

    const handleDeleteResponse = (id) => {
        setConversation(prev => {
            const updatedConversation = prev.filter(msg => msg.id !== id);

            // Count user and assistant messages
            const userMessages = updatedConversation.filter(msg => msg.role === 'user');
            const assistantMessages = updatedConversation.filter(msg => msg.role === 'assistant');

            // Identify last message indexes
            const lastUserIndex = updatedConversation.map(msg => msg.role).lastIndexOf('user');
            const lastAssistantIndex = updatedConversation.map(msg => msg.role).lastIndexOf('assistant');

            return updatedConversation.map((msg, index) => ({
                ...msg,
                multiple: (msg.role === 'user' && userMessages.length > 1 && index >= lastUserIndex - (userMessages.length - 1)) ||
                    (msg.role === 'assistant' && assistantMessages.length > 1 && index >= lastAssistantIndex - (assistantMessages.length - 1))
            }));
        });
    };

    const countLastMessages = (role) => {
        let lastIndex = conversation.length - 1;
        while (lastIndex >= 0 && conversation[lastIndex].role === role) {
            lastIndex--;
        }
        return conversation.length - 1 - lastIndex; // Count of last messages
    };

    const lastAssistantCount = countLastMessages('assistant');
    const lastUserCount = countLastMessages('user');



    return (
        <>
            <div className="chat__container">
                <div className="chat__wrapper">
                    {historyVerified &&
                            <div  className="search-form">
                                <div className="d-flex justify-content-between mt-1">
                                    <button className="btn btn-info btn-lg" disabled={closingDay} onClick={(e) => {closeDay(e)}}>

                                        {closingDay ? (
                                            <>
                                                Saving...
                                                <i className="fa fa-spinner fa-spin ml-2"></i>
                                            </>) : "Close Day"}
                                    </button>
                                    <button className="btn btn-success btn-lg" disabled={savingDay}  onClick={(e) => {saveDay(e)}}>
                                        {savingDay ? (
                                            <>
                                                Saving...
                                                <i className="fa fa-spinner fa-spin ml-2"></i>
                                            </>) : "Save Day"}
                                    </button>
                                </div>
                            </div>
                    }
                    {!historyVerified && (
                        <form className="search-form d-flex justify-content-between" onSubmit={handleGenerateHistory}>
                            <div className="form-group">
                                <label htmlFor="day_number" className="form-label">Day Number *</label>
                                <input type="text" id="day_number" className="form-control"
                                       placeholder="Enter Day Number"
                                       value={dayNumber}
                                       onChange={(e) => setDayNumber(e.target.value)}
                                       min="1"
                                       max="100" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="persona_number" className="form-label">Persona Number *</label>
                                <input type="text" id="persona_number" className="form-control"
                                       placeholder="Enter Persona Number"
                                       value={personaNumber}
                                       onChange={(e) => setPersonaNumber(e.target.value)} />
                            </div>

                            <button type="submit" className="btn btn-info btn-lg"
                                    disabled={historyLoading}
                                    style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                {historyLoading ? (
                                    <>Generating... <i className="fa fa-spinner fa-spin ml-2"></i></>
                                ) : (
                                    historyGenerated ? "Regenerate History" : "Generate History"
                                )}
                            </button>
                        </form>
                    )}

                    {historyGenerated && !historyVerified && (
                        <button className="btn btn-success mt-2" onClick={handleVerifyHistory}>
                            ✅ Verify History
                        </button>
                    )}

                    {historyVerified && (
                        <p className="alert alert-success mt-2">✅ History Verified</p>
                    )}

                    <div className="question_answer__wrapper__chatbot">
                        {error && <p className="error-message">{error}</p>}
                        <div className="form-group text-group">
                            <label htmlFor="persona_number" className="form-label">Persona</label>
                            <textarea name="" id="textarea"
                                      value={`${personaText}\n${assistantStep}`}
                                      ref={textareaRef}

                                     ></textarea>
                        </div>
                        {conversation.map((msg) => (

                            <div
                                key={msg.id}
                                className={`single__question__answer message ${msg.sender}`}
                                onDoubleClick={() => handleEdit(msg.id, msg.content)}
                            >
                            {msg.role === 'user' ? (
                            <div className="question_user">

                                    <div className="left_user_info" style={{ color: 'black',cursor: 'pointer',width:'100%'  }}>
                                        <img
                                            style={{ width: '35px' }}
                                            src={profileUser}
                                            alt="avatar"
                                        />
                                        {editingMessageId === msg.id ? (
                                            <div className="edit-mode"  style={{width:'100%'}}>
                                                 <textarea
                                                     className="editable_text"
                                                     style={{width:'100%'}}
                                                     value={editText}
                                                     ref={editTextRef}
                                                           onChange={(e) => setEditText(e.target.value)}
                                                 ></textarea>

                                                <button className="btn btn-danger " onClick={handleCancelEdit}><i className="fa fa-close"></i></button>
                                                <button className="btn btn-success " onClick={handleSaveEdit}><i className="fa fa-check"></i></button>
                                            </div>
                                        ) : (
                                        <>
                                            You: {msg.content}
                                            {msg.multiple && (
                                                <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                    }}
                                                       onClick={() => handleDeleteResponse(msg.id)}
                                                ></i>
                                            )}
                                        </>
                                        )}

                                    </div>
                            </div>
                            ) : msg.role === 'assistant' ?(
                            <div className="answer__area"  style={{width:'100%'}}>
                                <div className="thumbnail"  style={{marginTop:'10px' }}>
                                    <img
                                        style={{ width: '30px !important'}}
                                        src={logo}
                                        alt="avatar"
                                    />
                                </div>
                                {editingMessageId === msg.id ? (
                                    <div className="edit-mode"  style={{width:'100%'}}>
                                                 <textarea
                                                     className="editable_text"
                                                     style={{width:'100%'}}
                                                     value={editText.split("(MetaMindful):").pop().trim()}
                                                     ref={editTextRef}
                                                     onChange={(e) => setEditText(e.target.value)}
                                                 ></textarea>
                                        <button className="btn btn-danger btn-sm" onClick={handleCancelEdit}><i className="fa fa-close"></i></button>
                                        <button className="btn btn-success btn-sm" onClick={handleSaveEdit}><i className="fa fa-check"></i></button>
                                    </div>
                                ) : (
                                    <div  className="answer_main__wrapper" >

                                        <p className="disc">
                                            {msg.content.split("(MetaMindful):").pop().trim()}

                                            {msg.multiple && (
                                                <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                    }}
                                                    onClick={() => handleDeleteResponse(msg.id)}
                                                ></i>
                                            )}

                                        </p>

                                    </div>
                                )}
                            </div>
                            ):(
                                <div className="answer__area"  style={{width:'100%'}}>


                                        <div  className="answer_main__wrapper" >

                                            <p className="disc">
                                                System Message: {msg.content}

                                            </p>

                                        </div>

                                </div>

                            )}
                            </div>
                        ))}

                            </div>
                    {error && <p className="alert alert-danger">{error}</p>}
                    {message && <p className="alert alert-danger">{message}</p>}

                    {showUserWarning && <p className="alert alert-danger">Select only one Assistant response to proceed.</p>}
                    {showAssistantWarning && <p className="alert alert-danger">Select only one User response to proceed.</p>}
                    {historyVerified ? (
                    <div  className="search-form">
<div className="d-flex justify-content-between">

                        <button className="btn btn-info btn-lg"
                                disabled={userLoading || lastAssistantCount > 1}
                                onClick={(e) => {
                                    if (lastAssistantCount > 1) {
                                        setShowUserWarning(true);
                                    } else {
                                        setShowUserWarning(false);
                                        handleUserResponse(e);
                                    }
                                }}
                        >
                            {userLoading ? (
                                <>
                                    Generating...
                                    <i className="fa fa-spinner fa-spin ml-2"></i>
                                </>
                            ) : (
                                lastAssistantCount > 1
                                    ? "Select only one Assistant response to proceed"
                                    : conversation.length > 0 && conversation[conversation.length - 1].role === 'user'
                                        ? "Regenerate User Message"
                                        : "Generate User Message"
                            )}
                        </button>

                        {/* Generate Assistant Message Button */}
                        <button className="btn btn-success btn-lg"
                                disabled={assistantLoading || lastUserCount > 1}
                                onClick={(e) => {
                                    if (lastUserCount > 1) {
                                        setShowAssistantWarning(true);
                                    } else {
                                        setShowAssistantWarning(false);
                                        handleAssistantResponse(e);
                                    }
                                }}
                        >
                            {assistantLoading ? (
                                <>
                                    Generating...
                                    <i className="fa fa-spinner fa-spin ml-2"></i>
                                </>
                            ) : (
                                lastUserCount > 1
                                    ? "Select only one User response to proceed"
                                    : conversation.length > 0 && conversation[conversation.length - 1].role === 'assistant'
                                        ? "Regenerate Assistant Message"
                                        : "Generate Assistant Message"
                            )}
                        </button>
</div>
                    </div>):
                        (<><p className="alert alert-danger">First Generate and verify history</p></>)}

                </div>
            </div>
        </>
    );
};

export default BulkChat;
