// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import Chat from './pages/Chat';
import DailyChat from './pages/DailyChat';
import MultipleChat from './pages/MultipleChat';
import BulkChat from './pages/BulkChat';
import Profile from './pages/Profile';
import Password from './pages/Password';
import Register from './pages/auth/Register';
import ResetPassword from './pages/auth/ResetPassword';
import AdminLayout from './components/AdminLayout';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
//import Loading from './components/Loading'; // Import the Loading component
import { AuthProvider } from './hooks/useAuth';

const App = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation(); // Moved inside Router context

    useEffect(() => {
        const handleStart = () => setLoading(true);
        const handleComplete = () => setLoading(false);

        handleStart();
        const timeout = setTimeout(() => handleComplete(), 500); // Simulate loading time
        return () => clearTimeout(timeout);
    }, [location]);

    return (
        <>
            <AuthProvider>
            {/*    {loading && <Loading />}*/}
                <Routes>
                    <Route path="/" element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    } />
                    <Route path="/login" element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    } />
                    <Route path="/register" element={
                        <PublicRoute>
                            <Register />
                        </PublicRoute>
                    } />
                    <Route path="/reset-password" element={
                        <PublicRoute>
                            <ResetPassword />
                        </PublicRoute>
                    } />
                    <Route element={<PrivateRoute><AdminLayout /></PrivateRoute>}>
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/chat" element={<Chat />} />
                        <Route path="/daily-chat" element={<DailyChat />} />
                        <Route path="/multiple-chat" element={<MultipleChat />} />
                        <Route path="/bulk-chat" element={<BulkChat />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/change-password" element={<Password />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </>
    );
};

export default function RootApp() {
    return (
        <Router>
            <App />
        </Router>
    );
}
